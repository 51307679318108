import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useParams } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import ItemStore from '../stores/ItemStore'
import ItemScreenTeamDealHeader from '../components/items/ItemScreenTeamDealHeader'
import OrderStore from '../stores/OrderStore'
import ItemScreenHeader from '../components/items/ItemScreenHeader'
import TeamOrderComponent from '../components/items/TeamOrderComponent'
import DetailImageList from '../components/items/DetailImageList'
import ReviewListComponent from '../components/items/ReviewListComponent'
import BottomOrderBarWrapper from '../components/tab/BottomOrderBarWrapper'
import dynamicLinkUtils from '../utils/dynamicLinkUtils'
import UserStore from '../stores/UserStore'
import SoldOutModal from '../components/modal/SoldOutModal'
import AuthStore from '../stores/AuthStore'
import ItemList from '../components/items/ItemList'
import matrixUtils from '../utils/matrixUtils'
import { BottomSheet } from 'react-spring-bottom-sheet'
import ItemScreenTitleText from '../components/texts/ItemScreenTitleText'
import RelatedItemComponent from '../components/items/RelatedItemComponent'
import { isMobile } from 'react-device-detect'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import RenderRating from '../components/rating/RenderRating'
import GetRatingCount from '../components/texts/GetRatingCount'
import commaNumber from 'comma-number'
import TeamMemberHeader from '../components/items/TeamMemberHeader'
import ImageManager from '../images/ImageManager'
import goAppDownload from '../utils/appDownload'
import { Helmet } from 'react-helmet'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const ItemScreen = observer(() => {
  const history = useHistory()
  const params = dynamicLinkUtils.parseLink(window.location.href)
  const [open, setOpen] = useState(false)
  const [ableToLoad, setAbleToLoad] = useState(true)
  const { itemId } = useParams()
  const [showSoldOutModal, setShowSoldOutModal] = useState(false)
  // const [isLinkRewardTarget, setIsLinkRewardTarget] = useState(false)
  const [isLinkCheckDone, setIsLinkCheckDone] = useState(false)
  const [isInitialTeamDealLinkInvited, setIsInitialTeamDealLinkInvited] =
    useState(false)
  const [invitedOrderId, setInvitedOrderId] = useState('')
  const [linkInvitorId, setLinkInvitorId] = useState('')
  const [relatedItemOrdering, setRelatedItemOrdering] = useState([])
  const [relatedItemsInfo, setRelatedItemsInfo] = useState([])
  const [reviews, setReviews] = useState([])
  const [validTeamDeals, setValidTeamDeals] = useState([])
  const [needsPrivacyProtection, setNeedsPrivacyProtection] = useState(false)
  const [soldOut, setSoldOut] = useState(false)
  const reviewRate = GetRatingCount(ItemStore?.targetItemInfo).getRating
  const numberOfReview = commaNumber(
    (ItemStore.targetItemInfo?.totalRatingCount || 0) +
      (ItemStore.targetItemInfo?.totalRatingCountRandom || 0),
  )

  const notify = (message, timeSpan = 1500) =>
    toast(message, {
      autoClose: timeSpan,
      closeButton: false,
    })

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 200)
  }, [])

  useEffect(() => {
    // setTimeout을 안주면 카카오톡을 통해 앱을 설치한 경우 파라미터 값을 제대로 못 가져와서 원하는 화면으로 이동을 못하는 문제가 있음.
    setTimeout(() => {
      UserStore.setDynamicLinkParams({
        ...params,
        bottomSheetTimeOut: 0,
        itemId,
      })
    }, 200)
  }, [itemId])

  const checkIfLinkReceived = async () => {
    if (!AuthStore.token) {
      if (
        OrderStore.loadedUserOrdersList.filter(
          (order) => order.status !== 'pre-pay',
        ).length === 0 &&
        (params.invitorId || params.alwayzInvitorId) &&
        (params.teamDealId || params.alwayzTeamDealId)
      ) {
        setIsInitialTeamDealLinkInvited(true)
        setLinkInvitorId(params.invitorId || params.alwayzInvitorId)

        const orderResult = await backendApis.getOrderByUserIdAndTeamDealId(
          params.invitorId || params.alwayzInvitorId,
          params.teamDealId || params.alwayzTeamDealId,
        )
        if (orderResult?.status === 200) {
          // setIsInitialTeamDealLinkInvited(true)
          // // setIsLinkRewardTarget(true)
          // if (
          //   linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
          //   linkReceivedResult.data.paramsInfo?.invitorId
          // ) {
          //   setLinkInvitorId(
          //     linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
          //       linkReceivedResult.data.paramsInfo?.invitorId,
          //   )
          // }
          setInvitedOrderId(orderResult.data._id)
        }
      }
    } else if (
      OrderStore.loadedUserOrdersList.filter(
        (order) => order.status !== 'pre-pay',
      ).length === 0
    ) {
      const linkReceivedResult = await backendApis.getLinkReceivedInfo(itemId)
      if (linkReceivedResult?.status === 2000) {
        setIsInitialTeamDealLinkInvited(true)
        // setIsLinkRewardTarget(true)
        if (
          linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
          linkReceivedResult.data.paramsInfo?.invitorId
        ) {
          setLinkInvitorId(
            linkReceivedResult.data.paramsInfo?.alwayzInvitorId ||
              linkReceivedResult.data.paramsInfo?.invitorId,
          )
        }
        setInvitedOrderId(linkReceivedResult.data.orderId)
      }
    }

    setIsLinkCheckDone(true)
  }

  useEffect(() => {
    checkIfLinkReceived()
  }, [
    OrderStore.loadedUserOrdersList.length > 0,
    UserStore.uploadingUserLinkUsage,
  ])

  useEffect(() => {
    if (
      OrderStore.finishedLoadingUserOrders &&
      OrderStore.loadedUserOrdersList.filter(
        (order) => order.status !== 'pre-pay',
      ).length > 0
    ) {
      setIsInitialTeamDealLinkInvited(false)
      // setIsLinkRewardTarget(false)
    }
  }, [OrderStore.finishedLoadingUserOrders])

  useEffect(() => {
    const unblock = history.block((location, action) => {
      // backendApis.registerLogs(`${JSON.stringify({ location, action })}`)
      // console.log('#### history block', location, action)
      if (action === 'POP') {
        return false
      }
      return true
      // if (isBlock && action === 'POP') {
      //   console.log('#### blocked ####');
      //   return false;
      // }
    })
    return () => {
      // console.log(`unblock fired in item screen`)
      unblock()
    }
  }, [])

  const loadRelatedItemsInfo = async (itemOrdering, mediumCategoryId) => {
    // console.log(
    //   `medium category id state: ${JSON.stringify(
    //     mediumCategoryId,
    //   )}, ordering length: ${itemOrdering.length}`,
    // )
    setAbleToLoad(false)
    const loadingItemIndices = itemOrdering.slice(-32)
    if (
      mediumCategoryId !== null &&
      itemOrdering.length > 0
      // ItemStore.ItemStore.._id === itemId
      //  &&
      // ItemStore.ItemStore..categoryInfo?.mediumCategoryId !== undefined
    ) {
      const loadingItemIds = loadingItemIndices.map(
        (index) => ItemStore.itemIdsByMediumCategories[mediumCategoryId][index],
      )
      // console.log(
      //   `medium category id state is not null! loading item indices: ${JSON.stringify(
      //     loadingItemIndices,
      //   )}`,
      // )
      setRelatedItemOrdering(itemOrdering.slice(0, -32))
      const result = await backendApis.getItemsInfo(
        loadingItemIds.filter((loadingItemId) => loadingItemId !== itemId),
      )
      // console.log(`related item loading result: ${JSON.stringify(result)}`)
      if (result) {
        // matrixUtils.shuffleArray(result)
        setRelatedItemsInfo([...relatedItemsInfo, ...result])
      }
    }
    await sleep(50)
    setAbleToLoad(true)
  }

  const loadItemInfo = async () => {
    await ItemStore.initialLoadDataAssets()
    const loadedItemResult = await ItemStore.loadTargetItemInfo(itemId)
    if (loadedItemResult?.status === 200 && loadedItemResult.data) {
      setReviews(loadedItemResult.data.itemReviews || [])

      const {
        itemTitle,
        individualPurchasePrice,
        teamPurchasePrice,
        discountRate,
        categoryInfo,
        isPrivateItem,
      } = loadedItemResult.data
      if (
        categoryInfo?.smallCategoryId === 186 ||
        categoryInfo?.mediumCategoryId === 28 ||
        isPrivateItem
      ) {
        setNeedsPrivacyProtection(true)
      }
      // const existingTeamDeal = OrderStore.loadedUserOrdersList.find(
      //   (order) =>
      //     order.status === 'team-gathering' &&
      //     order.itemInfo.itemId === itemId &&
      //     order.teamDealId,
      // )
      if (loadedItemResult.data.soldOut) {
        // alert(
        //   '해당 상품은 현재 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
        // )
        // setShowSoldOutModal(true)
        console.log(`setting soldout to true`)
        setSoldOut(true)
      } else {
        let optionPrices = [...loadedItemResult.data.optionsInfo.optionPrices]
        let finalOptionPrices = [
          ...loadedItemResult.data.optionsInfo.optionPrices,
        ]
        let testOption = optionPrices[0]
        // console.log(`test option1: ${JSON.stringify(testOption)}`)
        while (testOption.length) {
          finalOptionPrices = []
          // for (const option in optionPrices) {
          //   if (option) {
          //     console.log(`concating option: ${JSON.stringify(option)}`)
          //     finalOptionPrices = finalOptionPrices.concat(option)
          //   }
          // }
          optionPrices.forEach((option) => {
            finalOptionPrices = finalOptionPrices.concat(option)
          })
          optionPrices = [...finalOptionPrices]
          testOption = finalOptionPrices[0]
        }
        // console.log(`final option prices: ${JSON.stringify(finalOptionPrices)}`)
        const soldOutTemp = finalOptionPrices.every((option) => option.sold_out)
        if (soldOutTemp) {
          // checked
          // alert(
          //   '해당 상품은 현재 품절되어서 주문이 불가능합니다. 더 많은 상품들을 보시려면 앱에서 확인해주세요!',
          // )
          // setShowSoldOutModal(true)
          setSoldOut(true)
        }
      }
      const { mediumCategoryId, largeCategoryId } = categoryInfo
      if (
        relatedItemsInfo.length === 0 &&
        ItemStore.categoryWeights[largeCategoryId?.toString()] &&
        ItemStore.categoryWeights[largeCategoryId?.toString()][
          mediumCategoryId?.toString()
        ]
      ) {
        console.log(
          `no related items present here!! loading related items info`,
        )
        // const { mediumCategoryId } = loadedItemInfo.categoryInfo
        // setMediumCategoryIdState(mediumCategoryId)
        const itemIndex =
          ItemStore.itemIdsByMediumCategories[mediumCategoryId]?.indexOf(itemId)
        console.log(
          `loaded item medium category id: ${mediumCategoryId}, index: ${itemIndex}`,
        )
        if (itemIndex !== undefined && itemIndex !== -1) {
          const itemOrdering =
            matrixUtils.calculateItemMatchResultsForSmallCategories(
              ItemStore.itemVectorsByMediumCategories[mediumCategoryId],
              // ItemStore.stateVectors[mediumCategoryId],
              ItemStore.itemVectorsByMediumCategories[mediumCategoryId][
                itemIndex
              ],
            )
          // console.log(`item ordering: ${JSON.stringify(itemOrdering.slice(0, 10))}`)
          // setRelatedItemOrdering(itemOrdering)

          await loadRelatedItemsInfo(itemOrdering, mediumCategoryId)
        }
      }
    } else {
      localStorage.removeItem('@alwayz@itemId')
      history.replace('/')
    }
  }

  useEffect(() => {
    if (itemId) {
      UserStore.setScreenName('ItemScreen_Web')
      // ItemStore.loadTargetItemInfo(itemId)
      loadItemInfo()
      // scroll to top
      window.scrollTo(0, 0)
    } else {
      history.push('/')
    }
  }, [itemId])

  useEffect(() => {
    if (
      ItemStore.targetItemInfo?.teamDeals &&
      ItemStore.targetItemInfo._id === itemId
    ) {
      const progressingTeamDeals = ItemStore.targetItemInfo.teamDeals.filter(
        (teamDeal) =>
          !teamDeal.teamCompleted &&
          new Date() <
            new Date(
              new Date(teamDeal.restartedAt || teamDeal.createdAt).getTime() +
                1000 * 60 * 60 * 24,
            ),
      )
      if (progressingTeamDeals.length > 0) {
        setValidTeamDeals(progressingTeamDeals)
        // setIsProgressingTeamDealPresent(true)
      } else {
        setValidTeamDeals(
          ItemStore.targetItemInfo?.teamDeals.filter(
            (teamDeal) => teamDeal.teamCompleted,
          ),
        )
        // setIsProgressingTeamDealPresent(false)
      }
    }
  }, [ItemStore.targetItemInfo?.teamDeals])

  const styles = {
    sectionDivider: {
      height: 8,
      backgroundColor: '#F5F5F5',
    },
    iconText: {
      marginTop: 12,
      marginBottom: 8,
      marginLeft: 16,
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
      flexDirection: 'row',
      alignItems: 'center',
    },
    divider: {
      height: 1,
      backgroundColor: '#E0E0E0',
      marginLeft: 16,
      marginRight: 16,
    },
    dividerInSection: {
      height: 1,
      backgroundColor: '#E0E0E0',
    },
  }
  const BottomSheetComponent = () => {
    return (
      <div
        style={{
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 24,
          }}
        >
          <ImageManager source='AppIcon' size={80} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 16,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              fontWeight: 'bold',
              fontSize: 20,
            }}
          >
            <div style={{ marginBottom: 2 }}>앱에서 가입하면</div>
            <div style={{ marginBottom: 6 }}>3만원 할인쿠폰팩을 드려요!</div>
            <div style={{ fontSize: 16, color: '#bbbbbb' }}>
              100원만 사도 무료배송
            </div>
          </div>
        </div>
        <button
          style={{
            width: '100%',
            marginBottom: 16,
            marginTop: 8,
            height: 48,
            backgroundColor: '#EA3f49',
            border: 'none',
            borderRadius: 10,
            fontSize: 18,
            color: '#FFF',
            fontWeight: 'bold',
            outline: 'none',
          }}
          type='button'
          onClick={() => {
            UserStore.setShowAppDownloadModal(true)
            goAppDownload('bottomSheet')
          }
          }
        >
          <span style={{ marginRight: 8 }}>앱에서 더 할인받기</span>
          <ImageManager source='NextWhite' size={14} />
        </button>
        {/* <button
          style={{
            width: '100%',
            height: 24,
            border: 'none',
            fontSize: 14,
            color: '#999999',
            backgroundColor: '#FFFFFF',
          }}
          type='button'
          onClick={() => setOpen(false)}
        >
          {isMobile ? '모바일 ' : ''}웹으로 볼게요
        </button> */}
      </div>
    )
  }

  const ReviewHeader = () => {
    return (
      <>
        <div style={styles.sectionDivider} />
        <ItemScreenTitleText
          text={`상품 후기 ${numberOfReview}건`}
          more
          moreText='전체보기'
          onClick={() => {
            history.push('/items/review', {
              itemId,
              reviews,
              numberOfReview,
            })
          }}
        />
        <div
          style={{
            backgroundColor: '#FFFFFF',
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 0,
            paddingBottom: 12,
          }}
        >
          <div
            style={{
              backgroundColor: '#FFF',
              paddingHorizontal: 16,
              paddingVertical: 12,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                backgroundColor: '#FAFAFA',
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 12,
                borderRadius: 8,
              }}
            >
              <RenderRating size={26} rate={reviewRate} />
              <div style={{ width: 12 }} />
              <div
                style={{ fontSize: 20, fontWeight: 'bold', color: '#9E9E9E' }}
              >
                {Math.ceil(reviewRate * 10) / 10}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            {reviews
              .filter((review) => review?.reviewImageUris[0])
              ?.slice(0, 5)
              ?.map((review) => (
                <ReviewListComponent
                  key={review._id}
                  review={review}
                  source='ReviewSummary'
                />
              ))}
            {reviews.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#BDBDBD',
                  fontSize: 14,
                  height: 64,
                }}
              >
                아직 구매후기가 없어요.
              </div>
            ) : (
              <div
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
              >
                <button
                  type='button'
                  style={{
                    height: 40,
                    width: '100%',
                    border: 'solid',
                    borderRadius: 4,
                    borderWidth: 1,
                    backgroundColor: '#EA3F49',
                    color: '#FFF',
                    borderColor: '#EA3F49',
                    fontSize: 16,
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 12,
                    paddingBottom: 12,
                  }}
                  onClick={() => {
                    history.push('/items/review', {
                      itemId,
                      reviews,
                      numberOfReview,
                    })
                  }}
                >
                  {numberOfReview} 개 구매 후기 전체보기
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
  return (
    <div
      style={{
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#ffffff',
      }}
    >
      <Helmet><script type="text/javascript">
          {`
          fbq('track', 'ViewContent', {content_type:'product', content_ids: ['${itemId?.toString()}'], contents: [{'id': '${itemId?.toString()}', 'quantity': 1}]});
          `}
        </script>
      </Helmet>
      <ToastContainer
        style={{ color: '#333333' }}
        position='top-center'
        autoClose={1500}
        hideProgressBar
        transition={Zoom}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        closeButton={false}
      />
      <div style={{ height: 72 }} />
      <ItemScreenHeader onClickFunc={() => {
        UserStore.setShowAppDownloadModal(true)
        goAppDownload('headerBanner')}
        } />
      {OrderStore.invitedTeamDealInfo?.membersInfo?.length > 0 &&
        UserStore.testGroup.teamMemberShowing === 'A' && <TeamMemberHeader />}
      <ItemScreenTeamDealHeader
        isLinkRewardTarget={isInitialTeamDealLinkInvited}
        validTeamDeals={validTeamDeals}
        itemId={itemId}
        reviews={reviews}
      />
      <SoldOutModal
        showModal={showSoldOutModal}
        setShowModal={setShowSoldOutModal}
      />

      {validTeamDeals.map((team, index) => (
        <div key={team._id}>
          {index !== 0 && (
            <div
              style={{
                height: 0.5,
                backgroundColor: '#E0E0E0',
                marginRight: 16,
                marginLeft: 16,
              }}
            />
          )}
          <TeamOrderComponent
            team={team}
            validTeamDeals={validTeamDeals}
            setValidTeamDeals={setValidTeamDeals}
            needsPrivacyProtection={needsPrivacyProtection}
            soldOut={soldOut}
            isViralItem={
              ItemStore.targetItemInfo?.isViralItem ||
              ItemStore.targetItemInfo?.isLotteryItem
            }
            onClickFunc={() => {
              UserStore.setShowAppDownloadModal(true)
              goAppDownload('teamOrder')
            }}
          />
        </div>
      ))}
      {validTeamDeals.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#BDBDBD',
            fontSize: 14,
            height: 64,
          }}
        >
          아직 모집 중인 팀이 없어요.
        </div>
      )}
      <ReviewHeader />
      {/* 연관 상품: {JSON.stringify(relatedItemsInfo[0])} */}
      <div style={styles.sectionDivider} />
      <ItemScreenTitleText text='상품 상세 설명' />
      <DetailImageList />
      {/* <div
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <button
          style={{
            width: '100%',
            border: 'solid',
            borderColor: '#E0E0E0',
            backgroundColor: '#FFF',
            borderWidth: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40,
            borderRadius: 4,
            color: '#333333',
            textDecoration: 'none',
          }}
          type='button'
          onClick={async () => {
            const sellerId = ItemStore.targetItemInfo.sellerInfo?.sellerId
            let sellerKakaoChatUrlTemp
            if (sellerId) {
              const result = await backendApis.getSellerKakaoChannel(sellerId)
              if (result?.status === 2000 && result.data) {
                sellerKakaoChatUrlTemp = result.data
              } else {
                sellerKakaoChatUrlTemp = 'http://pf.kakao.com/_rxlTbs/chat'
              }
            } else {
              sellerKakaoChatUrlTemp = 'http://pf.kakao.com/_rxlTbs/chat'
            }
            window.location.href = sellerKakaoChatUrlTemp
          }}
          // href='http://pf.kakao.com/_rxlTbs/chat'
          // target='_blank'
          // rel='noopener noreferrer'
        >
          <ImageManager source='Kakao' size={24} />
          <div style={{ marginLeft: 6, fontSize: 16 }}>
            카카오톡으로 1:1 문의하기
          </div>
        </button>
      </div> */}

      <div style={styles.dividerInSection} />
      <ItemScreenTitleText
        text={`상품 문의 (${
          (ItemStore.targetItemInfo.itemQuestions || []).length
        })`}
        more
        onClick={() => {
          history.push('/items/questions', { itemId })
        }}
      />
      <div style={styles.dividerInSection} />
      <ItemScreenTitleText
        text='배송/교환/반품 정책'
        more
        onClick={() => {
          history.push('/items/shipping-details')
        }}
      />
      {relatedItemsInfo.length >= 3 && (
        <>
          <div style={styles.sectionDivider} />
          <ItemScreenTitleText text='다른 고객님이 함께 많이 보는 상품' />
          <div
            style={{
              paddingRight: 16,
              marginTop: 4,
              display: 'flex',
              flexDirection: 'row',
              overflowY: 'hidden',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
            }}
          >
            {relatedItemsInfo?.map((item, index) => (
              <RelatedItemComponent
                itemInfo={item}
                index={index}
                key={item._id.toString() + index.toString()}
              />
            ))}
          </div>
        </>
      )}
      <div style={styles.sectionDivider} />
      {ItemStore.initialLoadingDataAssetsDone && (
        <ItemList
          categoryType='large'
          categoryId='total'
          enteringComponent='ItemScreenAlwayzSuggestionComponent'
        />
      )}

      <div
        className='special'
        style={{
          maxWidth: '480px',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            position: 'fixed',
            bottom: 56,
            zIndex: 1,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 16,
          }}
        >
          <div
            style={{
              flexDirection: 'column',
              backgroundColor: '#000000dd',
              borderRadius: 10,
              padding: 8,
              alignItems: 'center',
              color: '#ffffff',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: 2,
              }}
            >
              <ImageManager source='LuckyBox' size={18} />
              <div
                style={{
                  paddingLeft: 4,
                }}
              >
                앱 신규회원이면
              </div>
            </div>
            <div>팀 없이 구매 가능</div>
          </div>
          <div
            style={{
              width: 0,
              height: 0,
              backgroundColor: 'transparent',
              borderStyle: 'solid',
              borderBottomWidth: 10,
              borderRightWidth: 10,
              borderTopColor: 'transparent',
              borderRightColor: 'transparent',
              borderBottomColor: '#000000cc',
              borderLeftColor: 'transparent',
              transform: 'rotate(180deg)',
            }}
          />
        </div>
        <BottomOrderBarWrapper
          itemId={itemId}
          isEventDeal={ItemStore.targetItemInfo?.isEventDeal}
          teamDealId={params.teamDealId}
          invitorId={params.invitorId}
          isFromFeed={params.isFromFeed}
          needsPrivacyProtection={needsPrivacyProtection}
          soldOut={soldOut}
          isLinkFromApp={params.isLinkFromApp}
          // setIsLinkRewardTarget={setIsLinkRewardTarget}
          invitedOrderId={invitedOrderId}
          linkInvitorId={linkInvitorId}
          isInitialTeamDealLinkInvited={isInitialTeamDealLinkInvited}
          isLinkCheckDone={isLinkCheckDone}
          notify={notify}
          onClickFunc={() => {
            UserStore.setShowAppDownloadModal(true)
            goAppDownload('bottomOrderBar')}
          }
        />
      </div>

      <BottomSheet
        open={open}
        onDismiss={() => {
          setOpen(false)
        }}
      >
        <BottomSheetComponent />
      </BottomSheet>

      {/* {showAppDownloadFullScreen &&
        UserStore.testGroup?.appDownloadFullScreen === 'A' && (
          // <AppDownloadFullScreen />
          <AppDownloadFullScreen
            setShowAppDownloadFullScreen={setShowAppDownloadFullScreen}
          />
        )} */}
      <div
        style={{
          height: 170,
          paddingTop: 24,
          paddingLeft: 24,
          paddingBottom: 24,
          color: '#bdbdbd',
          backgroundColor: '#F5F5F5',
          lineHeight: 1.4,
        }}
      >
        <div>대표자: 강재윤 </div>
        <div>법인명: 주식회사 레브잇</div>
        <div>사업자등록번호: 179-81-02225</div>
        <div>주소: 서울특별시 관악구 남부순환로 220길 39</div>
        <div>통신판매번호: 2022-서울관악-0341</div>
      </div>
    </div>
  )
})

export default ItemScreen
