import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import UseWindowDimension from '../common/UseWindowDimension'
import ImageManager from '../../images/ImageManager'

// import { useHistory, useLocation, useParams } from 'react-router-dom'

// const MainImages = observer(() => {
//   const { width } = UseWindowDimension()
//   const [currentIndex, setCurrentIndex] = useState(0)
//   return (
//     <div
//       style={{
//         position: 'relative',
//         width: '100%',
//         height: 0,
//         paddingBottom: '100%',
//       }}
//     >
//       <div
//         onScroll={(e) => {
//           setCurrentIndex(Math.round(e.target.scrollLeft / width))
//         }}
//         style={{
//           position: 'absolute',
//           top: 0,
//           right: 0,
//           bottom: 0,
//           left: 0,
//           display: 'flex',
//           flexDirection: 'row',
//           overflowY: 'hidden',
//           overflowX: 'scroll',
//           scrollSnapType: 'x mandatory',
//         }}
//       >
//         {ItemStore.targetItemInfo.mainImageUris?.map((mainImageUri, index) => (
//           <div
//             key={mainImageUri}
//             style={{
//               scrollSnapAlign: 'start',
//               position: 'relative',
//               width: '100%',
//               height: '100%',
//             }}
//           >
//             <img
//               alt={mainImageUri}
//               src={mainImageUri}
//               style={{
//                 objectFit: 'cover',
//                 paddingRight: `${
//                   index === ItemStore.targetItemInfo.mainImageUris.length - 1
//                     ? 0
//                     : 100
//                 }%`,
//                 height: '100%',
//               }}
//             />
//           </div>
//         ))}
//       </div>
//       <div
//         style={{
//           position: 'absolute',
//           bottom: 12,
//           right: 16,
//           paddingLeft: 12,
//           paddingRight: 12,
//           color: '#FFF',
//           fontSize: 12,
//           height: 24,
//           backgroundColor: '#00000088',
//           borderRadius: 16,
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flexDirection: 'row',
//         }}
//       >
//         {currentIndex + 1}/{ItemStore.targetItemInfo.mainImageUris?.length}
//       </div>
//       {ItemStore.targetItemInfo?.isCheapest && (
//         <ImageManager
//           source='IsCheapestImage'
//           style={{
//             position: 'absolute',
//             left: 12,
//             top: 12,
//             width: 64,
//             height: 64,
//           }}
//         />
//       )}
//     </div>
//   )
// })

// export default MainImages

const MainImages = observer(() => {
  const { width } = UseWindowDimension()
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleScroll = (e) => {
    console.log(e.target.scrollLeft)
    // 전체 스크롤된 거리 / 화면 너비를 반올림하여 현재 인덱스를 구합니다.
    setCurrentIndex(Math.round(e.target.scrollLeft / width))
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',      // 부모가 가로로 100%를 차지
        height: 0,
        paddingBottom: '100%', // 세로는 가로와 같게 → 정사각형
      }}
    >
      {/* 실제 스크롤 컨테이너 */}
      <div
        onScroll={handleScroll}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'scroll',
          overflowY: 'hidden',
          scrollSnapType: 'x mandatory',
          WebkitOverflowScrolling: 'touch', // iOS에서 부드러운 스크롤
        }}
      >
        {ItemStore.targetItemInfo.mainImageUris?.map((mainImageUri, index) => (
          <div
            key={mainImageUri}
            style={{
              /* 
                각 슬라이드가 100% 너비를 차지하고,
                스크롤 시 스냅되도록 설정 
              */
              flex: '0 0 100%',
              scrollSnapAlign: 'start',
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              alt={mainImageUri}
              src={mainImageUri}
              draggable={false}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        ))}
      </div>
      {/* 현재 페이지 표시 (ex. 1/3) */}
      <div
        style={{
          position: 'absolute',
          bottom: 12,
          right: 16,
          paddingLeft: 12,
          paddingRight: 12,
          color: '#FFF',
          fontSize: 12,
          height: 24,
          backgroundColor: '#00000088',
          borderRadius: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        {currentIndex + 1}/{ItemStore.targetItemInfo.mainImageUris?.length}
      </div>
      {/* 최저가 등 마크 표시 */}
      {ItemStore.targetItemInfo?.isCheapest && (
        <ImageManager
          source='IsCheapestImage'
          style={{
            position: 'absolute',
            left: 12,
            top: 12,
            width: 64,
            height: 64,
          }}
        />
      )}
    </div>
  )
})

export default MainImages
